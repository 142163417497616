
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import React from "react";
import { PageGameComp } from "src/generated/page";
import Navbar from "src/components/Navbar/Navbar";
import Hero from "../components/Home/Hero";
import Content from "src/components/Home/Content";
import Footer from "src/components/Footer/Footer";

const Home: PageGameComp = () => {
  return (
    <div className="bg-backdrop text-white">
      <Navbar />
      <Hero />
      <Content />
      <Footer />
    </div>
  );
};

export default Home;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  