import React from "react";
import Link from "next/link";
import useTranslation from 'next-translate/useTranslation';

const Footer: React.FunctionComponent = () => {
  const { t } = useTranslation('components/Footer/footer');

  const footerLinks = [
    {
      name: "about_us",
      link: "/#about-us",
    },
    {
      name: "terms_of_use",
      link: "/privacy-policy",
    },
    {
      name: "privacy_policy",
      link: "/privacy-policy",
    },
    {
      name: "become_a_gamer",
      link: "https://kohai.gg",
    },
  ];

  const socialLinks = [
    {
      name: "kohai-instagram",
      imageLink: "/instagram.svg",
      link: "https://www.instagram.com/kohai_esports/",
    },
    {
      name: "kohai-facebook",
      imageLink: "/facebook.svg",
      link: "https://facebook.com/Kohai.Esports",
    },
    {
      name: "kohai-tiktok",
      imageLink: "/tiktok.svg",
      link: "https://www.tiktok.com/@kohai_esports_iwg_oe?",
    },
  ];

  return (
    <footer className="bg-primary py-20 border-t border-blue-600 px-6">
      <section className="max-w-6xl mx-auto w-full flex flex-col">
        <img src="/kohai-logo.png" alt="kohai-logo" className="w-52 mb-8" />

        <div className="md:flex md:justify-between md:items-end mb-10">
          <div className="md:flex md:flex-wrap max-w-xl">
            {footerLinks.map(({ name, link }, index) => (
              <Link href={link} key={index}>
                <a className="text-blue-200 font-bold text-lg py-2.5 block md:inline-block md:w-1/2">{t(name)}</a>
              </Link>
            ))}
          </div>

          <div className="md:flex md:flex-col md:items-end">
            <div className="flex">
              <img src="/bm.svg" alt="bm" className="mr-3 w-7" />
              <p className="text-blue-200 font-bold text-lg tracking-wider py-2.5">
                Malaysia
              </p>
            </div>
            <div className="flex justify-start md:justify-center items-center py-2.5">
              {socialLinks.map(({ name, link, imageLink }, index) => (
                <Link href={link}>
                  <a>
                    <img
                      key={index}
                      src={imageLink}
                      alt={name}
                      className="w-7 mr-5 md:mr-0 md:ml-5 flex-shrink-0"
                    />
                  </a>
                </Link>
              ))}
            </div>
          </div>
        </div>

        <p className="text-blue-200 opacity-75 text-right">
          &copy; {t('copyright_kohai')} {new Date().getFullYear()}
        </p>
      </section>
    </footer>
  );
};

export default Footer;
