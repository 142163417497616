import React, { useState } from "react";
import useTranslation from 'next-translate/useTranslation';

interface IRevealProps {
  reveal: boolean;
}

const GamerReveal: React.FC<IRevealProps> = ({ reveal }) => {
  const { t } = useTranslation('components/Home/whyKohai');
  
  return (
  <div className="absolute flex justify-center items-center">
    <img
      src="/why-kohai-gamer.png"
      alt="why-kohai-gamer"
      className={`rounded-xl transition duration-300 transform ml-20 ${
        reveal ? "-translate-x-10 opacity-100" : "translate-x-10 opacity-0"
      }`}
      style={{ height: "700px" }}
    />

    <div
      className={`max-w-md transition duration-300 transform mb-20 ${
        reveal ? "translate-x-10 opacity-100" : "-translate-x-10 opacity-0"
      }`}
    >
      <p className="text-white text-2xl font-bold tracking-wider mb-3">
        {t('why_kohai')}
      </p>
      <h2 className="nunito-sans font-bold text-yellow-300 text-5xl leading-tight mb-4">
        {t('real_time')} &#38; {t('timed_gaming_sessions')}
      </h2>
    </div>
  </div>
  );
};

const PartnerReveal: React.FC<IRevealProps> = ({ reveal }) => {
  const { t } = useTranslation('components/Home/whyKohai');

  return (
    <div className="absolute flex justify-center items-center">
      <div
        className={`max-w-md transition duration-300 transform mb-20 ${
          reveal ? "-translate-x-10 opacity-100" : "translate-x-10 opacity-0"
        }`}
      >
        <p className="text-white text-2xl font-bold tracking-wider mb-3">
          {t('why_kohai')}
        </p>
        <h2 className="nunito-sans font-bold text-yellow-300 text-5xl leading-tight mb-4">
          {t('your_accounts_are_protected')}
        </h2>
      </div>

      <img
        src="/why-kohai-partner.png"
        alt="why-kohai-partner"
        className={`rounded-xl transition duration-300 transform mr-20 ${
          reveal ? "translate-x-10 opacity-100" : "-translate-x-10 opacity-0"
        }`}
        style={{ height: "700px" }}
      />
    </div>
  )
};

const WhyKohai: React.FunctionComponent = () => {
  const { t } = useTranslation('components/Home/whyKohai');
  const [reveal, setReveal] = useState<boolean>(false);

  return (
    <div className="relative h-screen flex justify-center items-center overflow-hidden py-24 text-black mb-24">
      <div className="container max-w-5xl">
        <div
          className="flex flex-col justify-center items-center"
          onMouseEnter={() => setReveal(true)}
          onMouseLeave={() => setReveal(false)}
        >
          <h2
            className={`absolute nunito-sans font-bold text-yellow-300 text-6xl leading-tight mb-4 transition duration-300 transform ${
              reveal ? "opacity-0 -translate-y-20" : "opacity-100 translate-y-0"
            }`}
          >
            {t('why_kohai?')}
          </h2>

          {false ? (
            <GamerReveal reveal={reveal} />
          ) : (
            <PartnerReveal reveal={reveal} />
          )}
        </div>
      </div>
    </div>
  );
};

export default WhyKohai;
