import React from "react";
import useTranslation from 'next-translate/useTranslation';

const AboutUs: React.FunctionComponent = () => {
  const { t } = useTranslation('components/Home/aboutUs');

  return (
    <div id="about-us" className="relative overflow-hidden py-24 text-black">
      <div className="container max-w-5xl">
        <div className="md:grid md:grid-cols-4 md:gap-6">
          <h2 className="nunito-sans font-bold text-yellow-300 text-5xl mb-4">
            {t('about_us')}
          </h2>
          <div className="col-span-3">
            <p className="nunito-sans text-2xl text-white">
              {t('your_love_and_expertise_for_gaming_can_now_be_turned_into_a_lucrative_opportunity')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
