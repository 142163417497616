import React from "react";
import WhyKohai from "src/components/Home/WhyKohai";
import NotAll from "src/components/Home/NotAll";
import Trusted from "src/components/Home/Trusted";
import AboutUs from "src/components/Home/AboutUs";
import ReadyToLevelUp from "src/components/Home/ReadyToLevelUp";

const Content = () => (
  <section className="relative bg-primary">
    <img src="/bg-frame.svg" alt="" className="absolute top-0 w-full" />
    <div className="relative">
      <WhyKohai />
      <NotAll />
      <Trusted />
      <AboutUs />
      <ReadyToLevelUp />
    </div>
  </section>
);

export default Content;
