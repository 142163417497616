import React from "react";
import Link from "next/link";
import useTranslation from 'next-translate/useTranslation';

const ReadyToLevelUp: React.FunctionComponent = () => {
  const { t } = useTranslation('components/Home/readyToLevelUp');

  return (
    <div className="relative overflow-hidden py-24 text-black">
      <div className="container max-w-5xl">
        <div className="flex flex-col justify-center items-center">
          <h2 className="nunito-sans font-bold text-yellow-300 text-5xl leading-tight mb-4">
            {t('ready_to_level_up')}
          </h2>

          <div className="mb-10">
            <p className="nunito-sans text-2xl text-blue-100 opacity-75">
              {t('download_and_sign_up_to_join_the_revolution')}
            </p>
          </div>

          <div className="grid grid-cols-2 gap-x-6" style={{maxWidth: 420}}>
            <Link href="https://play.google.com/store/apps/details?id=com.kohai.partner" passHref>
              <img
                src="/google-play-badge.svg"
                alt="kohai-google-play"
                className="w-full shadow-lg cursor-pointer"
              />
            </Link>
            <Link href="https://apps.apple.com/my/app/kohai-partner/id1544037912" passHref>
              <img
                src="/app-store-badge.svg"
                alt="kohai-app-store"
                className="w-full shadow-lg cursor-pointer"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReadyToLevelUp;
