import Link from "next/link";
import React from "react";
import useTranslation from 'next-translate/useTranslation';

const Hero: React.FunctionComponent = () => {
  const { t } = useTranslation('components/Home/hero');

  return (
    <div
      className="relative overflow-hidden bg-cover bg-right"
      style={{ backgroundImage: "url('/bg-hero.png')" }}
    >
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 py-20 sm:pb-16 md:pb-20 lg:max-w-3xl lg:w-full lg:pb-28 xl:pb-32">
          <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="text-center lg:text-left">
              <h1 className="nunito-sans font-bold text-yellow-300 text-4xl leading-1 sm:text-5xl md:text-6xl">
                {t('flaunt_your_gaming_expertise_and_earn')}
              </h1>

              <p className="mt-3 text-base sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                {t('earn_with_us_monetise_your_skill_enjoy_the_gaming_thrill')}
              </p>

              <Link href="/sign-up" passHref>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start mb-8">
                  <div className="rounded-md shadow">
                    <span className="font-teko w-full flex items-center justify-center px-8 py-3 text-base rounded-md text-white hover:bg-primary md:py-4 md:text-2xl md:px-10 uppercase cursor-pointer btn-primary">
                      {t('become_a_partner')}
                    </span>
                  </div>
                </div>
              </Link>

              <div className="grid grid-cols-2 gap-x-5" style={{maxWidth: 440}}>
                <Link href="https://play.google.com/store/apps/details?id=com.kohai.partner" passHref>
                  <img
                    src="/google-play-badge.svg"
                    alt="kohai-google-play"
                    className="w-full mr-3 flex-shrink-0 shadow-lg cursor-pointer"
                  />
                </Link>
                <Link href="https://apps.apple.com/my/app/kohai-partner/id1544037912" passHref>
                  <img
                    src="/app-store-badge.svg"
                    alt="kohai-app-store"
                    className="w-full mr-3 flex-shrink-0 shadow-lg cursor-pointer"
                  />
                </Link>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="bg-primary" style={{ marginTop: 150 }}>
        <div className="container">
          <div className="grid md:grid-cols-2 gap-6 ">
            <Link href="/sign-up" passHref>
              <div
                className="hero-game-cta cursor-pointer transition duration-200 transform -translate-y-20 hover:-translate-y-24 rounded"
                style={{ backgroundImage: "url('/bg-pubg.jpg')" }}
              >
                <div className="nunito-sans caption text-center text-xl font-bold tracking-wider">
                  {t('become_a_partner')}
                </div>
              </div>
            </Link>
            <Link href="/sign-up" passHref>
              <div
                className="hero-game-cta cursor-pointer transition duration-200 transform -translate-y-20 hover:-translate-y-24 rounded"
                style={{ backgroundImage: "url('/bg-mlbb.jpg')" }}
              >
                <div className="nunito-sans caption text-center text-xl font-bold tracking-wider">
                  {t('become_a_partner')}
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
