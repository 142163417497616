import React from "react";
import useTranslation from 'next-translate/useTranslation';

const items = [
  {
    imageLink: "no-risk.svg",
    imageName: "no-risk",
    text: "you_will_not_get_banned_nor_experience_any_risks_to_your_account",
  },
  {
    imageLink: "/data-secured.svg",
    imageName: "data-secured",
    text:
      "all_your_personal_data_is_kept_safe_and_will_not_be_disclosed_to_any_party_in_accordance_with_the_pdpa",
  },
  {
    imageLink: "/bank-safe.svg",
    imageName: "bank-safe",
    text:
      "with_a_third_party_payment_process_you_wont_need_to_reveal_banking_information",
  },
];

const Trusted: React.FunctionComponent = () => {
  const { t } = useTranslation('components/Home/trusted');

  return (
    <div className="relative overflow-hidden py-24 text-black">
      <div className="container max-w-7xl">
        <div className="flex flex-col justify-center items-center">
          <h2 className="nunito-sans max-w-2xl font-bold text-yellow-300 text-center text-4xl leading-tight mb-12">
            {t('kohai_dot_gg_is_trusted_credible_and_legally_compliant')}
          </h2>

          <div className="grid md:grid-cols-3 my-6 gap-x-24">
            {items.map(({ imageLink, imageName, text }, index) => (
              <div className="mt-4 mb-12 pb-10">
                <div key={index} className="flex flex-col">
                  <img
                    src={imageLink}
                    alt={imageName}
                    className="flex-shrink-0 h-36 mb-10"
                  />
                  <p className="text-white text-xl text-center">{t(text)}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trusted;
