import React, { useState } from "react";
import useTranslation from 'next-translate/useTranslation';

const Trusted: React.FunctionComponent = () => {
  const { t } = useTranslation('components/Home/notAll');
  const [realtimeHover, setRealtimeHover] = useState<boolean>(false);
  const [scheduledHover, setScheduledHover] = useState<boolean>(false);
  const [chatroomHover, setChatroomHover] = useState<boolean>(false);

  const items = [
    {
      imageLink: "realtime.png",
      imageHoverLink: "realtime-hovered.png",
      imageName: "realtime",
      title: "real_time",
      text: "get_matched_and_play_in_real_time_with_your_partners",
      state: realtimeHover,
      setState: (state) => setRealtimeHover(state),
    },
    {
      imageLink: "/scheduled.svg",
      imageHoverLink: "scheduled-hovered.png",
      imageName: "scheduled",
      title: "scheduled",
      text: "need_to_eat_your_lunch_schedule_the_match_up_for_later",
      state: scheduledHover,
      setState: (state) => setScheduledHover(state),
    },
    {
      imageLink: "/chatroom.png",
      imageHoverLink: "chatroom-hovered.png",
      imageName: "chatroom",
      title: "chatroom",
      text: "you_re_chatting_with_your_match_partners_instantly",
      state: chatroomHover,
      setState: (state) => setChatroomHover(state),
    },
  ];

  return (
    <div className="relative overflow-hidden py-24 text-black">
      <div className="container max-w-6xl">
        <div className="flex flex-col justify-center items-center">
          <h2 className="nunito-sans max-w-2xl font-bold text-yellow-300 text-center text-5xl leading-tight tracking-wide mb-3">
            {t('thats_not_all')}
          </h2>

          <div className="mb-24">
            <p className="nunito-sans text-2xl text-blue-100 opacity-75 text-center">
              {t('the_app_is_more_than_a_place_to_get_better_at_gaming')}
            </p>
          </div>

          <div className="grid md:grid-cols-3 mb-6 gap-x-8">
            {items.map(
              (
                {
                  imageLink,
                  imageHoverLink,
                  imageName,
                  title,
                  text,
                  state,
                  setState,
                },
                index
              ) => (
                <div
                  key={index}
                  className="flex flex-col items-center md:items-start mb-10"
                  onMouseEnter={() => setState(true)}
                  onMouseLeave={() => setState(false)}
                >
                  <div className="relative w-16 h-16 mb-3">
                    <img
                      src={imageLink}
                      alt={imageName}
                      className={`absolute top-0 left-0 -ml-2 flex-shrink-0 transition duration-200 transform  ${
                        state
                          ? "opacity-0 -translate-y-3"
                          : "opacity-100 translate-y-0"
                      }`}
                    />
                    <img
                      src={imageHoverLink}
                      alt={imageName}
                      className={`absolute top-0 left-0 -ml-2 flex-shrink-0 transition duration-200 transform  ${
                        state
                          ? "opacity-100 -translate-y-3"
                          : "opacity-0 translate-y-0"
                      }`}
                    />
                  </div>

                  <p className="text-yellow-300 font-bold text-lg tracking-wider mb-4">
                    {t(title)}
                  </p>
                  <p className="text-blue-200 text-center md:text-left">{t(text)}</p>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trusted;
